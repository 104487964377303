<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Subscribe CableTv 🚀">
                    <div class="text-center" v-if="load">
                        <b-spinner variant="primary" label="Text Centered" style="width: 3rem; height: 3rem;" class="mr-1" />
                    </div>
                    <b-container v-else>
                     <b-form    @submit.prevent="submitform">
    
                        <b-row align-v="center" class="mt-3 mb-3">
    
    
                            <b-col v-for="airtime in cables" :key="airtime.id" cols="6" md="3" class="mb-1 mx-auto">
                                <b-img :style="addstyle(airtime.cabletvcode)" @click="selectitem(airtime.cabletvcode)" thumbnail size="70px" fluid :src="mainurl + airtime.cabletvimage" /></b-col>
    
                        </b-row>
                  
    
                        <b-form-group label="Smartcard/Decoder Number" label-for="number">
                            <b-form-input v-model="number" id="number" size="lg" placeholder="Smartcard/Decoder Number" />
                        </b-form-group>


                        <b-form-group label="Decoder Name" label-for="name" v-if="verify">
                            <b-form-input v-model="name" id="name" size="lg" placeholder="Decoder Name" readonly />
                        </b-form-group>

                          <b-form-group label="Select Cabletv Plan" label-for="plan" v-if="(usercableplans != null) && verify">
                            <v-select v-model="selectedplan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" taggable push-tags :options="usercableplans" placeholder="Please Select CableTv Plan" class="select-size-lg" />
    
    
    
                        </b-form-group>


                         <b-form-group v-if="(price != null) && (usercableplans != null)" label="Cabletv Amount" label-for="amount">
                            <b-input-group prepend="₦" append>
                                <b-form-input :placeholder="price" prepend="₦" disabled size="lg" />
                            </b-input-group>
                        </b-form-group>
                        
    
                         <div class="demo-inline-spacing">
                         <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" block variant="primary" :disabled="process">
                         <b-spinner v-if="process"
          small
          type="grow"
        />  
                        <span v-else>{{!verify ? 'Continue': "Proceed"}}</span>  
                            </b-button>
                        </div>
      </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BAvatar, BFormRadio, BForm, BInputGroup, BSpinner, BCardText, BButton, BFormInput, BImg, BFormGroup, BContainer, BRow, BCol } from 'bootstrap-vue'

import vSelect from 'vue-select'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SelectCabletv from '../components/SelectCabletv.vue';
import useServices from "@/auth/jwt/userServices"
export default {
    components: {
        BCard,
        BImg,
        vSelect,
        SelectCabletv,
        BForm,
        BContainer,
        BSpinner,
        BFormRadio,
        BInputGroup,
        ToastificationContent,
        BFormInput,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },


    data() {
        return {
            load: false,
            verify: false,
              process: false,
              name: "",
            userdetails: null,
            price: null,
            usercableplans: null,
            cables: null,
            mainurl: jwtDefaultConfig.mainurl,
            number: null,
            selectedplan: null,
            selected: null,
            selectedtv : null,
           
        }
    },
   
    created() {
        this.getcabletv();
    },
        watch: {
        selectedplan: function name(value) {
           
                 this.price = value.availablePricingOptions[0].price.toString();
            this.selected = value.availablePricingOptions[0];
           
           
            console.log(value)

        },


        number: function(value) {

   this.name = null,
     this.userdetails = null;
     this.usercableplans =null;
         this.verify = false;
         console.log(value);


        }
    },


    methods: {



        submitform (){
            if (this.verify) {
                this.processtransaction();
          
            } else {
                this.verifycabletv();
            }
        },
        addstyle(id) {
            return this.selectedtv == id ? "border: 0.3rem solid green" : "papa"
        },

        selectitem(id) {
             this.name = null,
     this.userdetails = null;
     this.usercableplans =null;
         this.verify = false;
            this.selectedtv = id;
        },


        async getcabletv() {
            this.load = true;
            await useServices.getcabletvs().then((result)=> {
                this.cables = result.data.data
            });

            this.load = false;

        },


  async verifycabletv() {
    
  if (this.selectedtv != null) {
                this.process = true;
          
            try {
                await useServices.validatecabletvs({
                    cabletv_number: this.number,
                    provider: this.selectedtv,
                }).then((result) => {
                     this.process = false;


                    if (result.data.status == true) {
                       
                               this.name = result.data.data.userdetails.name;
                              this.userdetails = result.data.data.userdetails.name;
                              this.usercableplans = result.data.data.plans;
                        

                        
  
                        this.verify = true;
                        console.log(result.data)

                    } else {
  
                        this.$swal({
                            title: 'Error!',
                            text: result.data.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })



                    }



                });
            } catch (error) {
                 this.process = false;
                console.log(error.response.status)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }

            }

            this.process = false;

   
          } else {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: "Please Select A Cable Tv",
                    },
                  })
          }
       

        },

        async processtransaction() {
            var pram = {};
             
                   pram = {
                    cabletv_amount: this.selected.price,
                    decoder_number: this.number,
                    cabletv_type: this.selectedtv,
                    planname: this.selectedplan.name,
                    code: this.selectedplan.code,
                    period: this.selected.invoicePeriod,
                }
                
            

       

          console.log(pram);

        
            try {
              this.process = true;
                await useServices.processvabletvs(pram).then((result) => {
  this.process = false;
                 if (result.data.status == true) {
                        console.log(result.data.data.hash)

                        this.$router.push({name: "transaction", params: { hash: result.data.data.hash}});

                        console.log(result.data.data.hash)
                 } else {
                        this.$swal({
                            title: 'Error!',
                            text: result.data.data.message,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                 }
               


                });
            } catch (error) {
               this.process = false;
                console.log(error.response.status)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }

            }

            this.process = false;



        }
    },





}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>


