<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Subscribe CableTv 🚀">
                    <div class="text-center" v-if="load">
                        <b-spinner variant="primary" label="Text Centered" style="width: 3rem; height: 3rem;" class="mr-1" />
                    </div>
                    <b-container v-else>
                     <b-form    @submit.prevent="processtransaction">
    
                        <b-row align-v="center" class="mt-3 mb-3">
    
    
                            <b-col v-for="airtime in userairtime" :key="airtime.id" cols="6" md="3" class="mb-1 mx-auto">
                                <b-img :style="addstyle(airtime.cabletvcode)" @click="selectitem(airtime.cabletvcode)" thumbnail size="70px" fluid :src="mainurl + airtime.cabletvimage" /></b-col>
    
                        </b-row>
                  
    
                        <b-form-group label="Smartcard/Decoder Number" label-for="number">
                            <b-form-input v-model="phone" id="number" size="lg" placeholder="Smartcard/Decoder Number" />
                        </b-form-group>
                        
    
                        <div class="demo-inline-spacing">
                            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" block variant="primary">
                                Continue
                            </b-button>
                        </div>
      </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BAvatar, BFormRadio, BForm, BSpinner, BCardText, BButton, BFormInput, BImg, BFormGroup, BContainer, BRow, BCol } from 'bootstrap-vue'

import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useServices from "@/auth/jwt/userServices"
export default {
    components: {
        BCard,
        BImg,

        BForm,
        BContainer,
        BSpinner,
        BFormRadio,
        ToastificationContent,
        BFormInput,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },


props: {userairtime: {
      type: Object,
      default: () => [],
    },},
    data() {
        return {
            load: false,
            verify: false,
            userairtime: [],
            selectedairtime: null,
            mainurl: jwtDefaultConfig.mainurl,
            phone: null,
            pin: null,
            amount: null,
        }
    },
   
    created() {
        this.getcabletv();
    },

    methods: {
        addstyle(id) {
            return this.selectedairtime == id ? "border: 0.3rem solid green" : "papa"
        },

        selectitem(id) {
            this.selectedairtime = id;
        },


       


    },





}
</script>

